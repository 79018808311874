.page {
  flex: 1;
  overflow: scroll;
  border-radius: 8px;
  display: flex;
  height: 100%;
  flex-direction: column;

  .pageWrap {
    padding: 44px 70px 44px 110px;

    .actions {
      display: flex;
      align-items: center;
      margin-bottom: 55px;

      .uploadBtns {
        margin-right: 20px;

        :global(.ant-btn) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

          &+ :global(.ant-btn) {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }

      &> :global(.ant-btn) {
        &+ :global(.ant-btn) {
          margin-left: 20px;
        }
      }
    }

    .export {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
}